
import {Component, Prop, Vue} from "vue-property-decorator";
import StatusBoard from "@/views/StatusBoard.vue";
import StreamingDemo from "@/views/StreamingDemo.vue";
import store, {ACTIONS} from "@/store/store";
import {State} from "@/store/decorators";
import Stream from "@/components/Stream.vue";
import {PortalStage} from "@/types";

@Component({
    components: {
        Stream,
        StreamingDemo,
        StatusBoard,
    },
})
export default class SelfService extends Vue {
    @Prop({type: String, default: PortalStage.local}) private portalStage!: PortalStage;
    @State("isPublicDemo") isPublicDemo!: boolean;
    private tabSelected = null;

    beforeMount() {
        if (this.isPublicDemo) {
            document.title = "3D Web Streaming Demo";
        }
        store.state.portalStage = this.portalStage;
    }

    private changeTab(tabId: number): void {
        if (tabId === 0) {
            // selecting status-board tab
            // @ts-ignore
            this.$refs.streamingDemoRef?.disconnectStream();
            const stage = store.state.stage;
            const region = store.state.region;
            store.dispatch(ACTIONS.START_POLLING_MATCHMAKER, {stage, region});
        }
        if (tabId === 1) {
            // selecting streaming-demo tab
            // @ts-ignore
            store.dispatch(ACTIONS.STOP_POLLING_MATCHMAKER);
        }
    }
}
