
import {Component, Prop, Vue} from "vue-property-decorator";

type DropdownItem = {text: string; value: any};
export type DropdownItems = DropdownItem[];

@Component({})
export default class Dropdown extends Vue {
    /**
     * If 'items' is provided as DropdownItems, the returned 'value' of this component will be the selected items 'value' property
     */
    @Prop({type: Array, default: []}) private items!: DropdownItems | string[];
    @Prop({default: ""}) private value!: any;
    @Prop({type: String, default: ""}) private label!: string;
    @Prop({type: String, default: ""}) private hint!: string;
    @Prop({type: String, default: ""}) private errorMessage!: string;
    @Prop({type: Boolean, default: false}) private disabled!: boolean;
    @Prop({type: Boolean, default: false}) private allowFreeText!: boolean;

    get refinedValue(): string | DropdownItem {
        if (!this.value) {
            if (this.items.length > 0) {
                if (typeof this.items[0] === "object" && this.items[0].value) {
                    return this.items[0].value;
                } else {
                    return this.items[0];
                }
            }
        }

        return this.value;
    }

    private emitNewValue(dropdownItem: DropdownItem): void {
        if (dropdownItem && typeof dropdownItem === "object" && dropdownItem.value) {
            this.$emit("input", dropdownItem.value);
        } else {
            this.$emit("input", dropdownItem);
        }
    }
}
