import Vue from "vue";
import Vuex, {Store} from "vuex";
import {configFromQueryParameter, mapPortalStageToDisplayedStages} from "@/utils";
import {
    allRegions,
    MatchMakerDataEntry,
    PortalStage,
    Region,
    ServiceMarketsCombination,
    Stage,
    StageRegionCombination,
} from "@/types";
import {kvps} from "@/brand-config";
import {sleep} from "@ave2cloud/jetstream/dist/src/utils";
import {ClientConfig} from "@ave2cloud/jetstream/dist/src/types";

Vue.use(Vuex);

export enum MUTATIONS {
    SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE",
    SET_INFO_MESSAGE = "SET_INFO_MESSAGE",
    CLEAR_INFO_MESSAGE = "CLEAR_INFO_MESSAGE",
}

export enum ACTIONS {
    UPDATE_MATCHMAKER_DATA = "UPDATE_MATCHMAKER_DATA",
    START_POLLING_MATCHMAKER = "START_POLLING_MATCHMAKER",
    STOP_POLLING_MATCHMAKER = "STOP_POLLING_MATCHMAKER",
    UPDATE_INSTANCE_STATUS_MESSAGE = "UPDATE_INSTANCE_STATUS_MESSAGE",
}

export type State = {
    stage: Stage;
    region: Region;
    portalStage: PortalStage;
    pollingInterval: number | NodeJS.Timeout;
    matchMakerData: MatchMakerDataEntry[];
    isUpdatingMatchMakerData: boolean;
    infoMessage: string;
    infoMessageIsError: boolean;
    isPublicDemo: boolean;
};

export const defaultState: State = {
    stage: Stage.DEV,
    region: Region.EU_WEST_1,
    portalStage: PortalStage.local,
    pollingInterval: 0,
    matchMakerData: [
        {
            aves: {
                freeAves: [],
                inUseAves: [],
            },
            brand: kvps[0],
            id: "",
            users: [],
            versions: [],
        },
    ],
    isUpdatingMatchMakerData: false,
    infoMessage: "",
    infoMessageIsError: false,
    isPublicDemo: window.location.hostname.startsWith("demo."),
};

// allow setting state information for test setup
export function createStore(state?: Partial<State>): Store<State> {
    return new Vuex.Store<State>({
        // use spread-operator to set missing properties in 'state' from the default state
        state: {...defaultState, ...state},
        getters: {
            clientConfigFromQueryParameter(state): ClientConfig {
                const config = configFromQueryParameter();
                if (!config.stage || !config.service || !config.brand) {
                    throw new Error("Received invalid configuration", config);
                }
                state.stage = config.stage;
                return {service: config.service, market: config.market, brand: config.brand};
            },
            startVideoAutomatically(): boolean {
                try {
                    return configFromQueryParameter().startStreamAutomatically;
                } catch (error) {
                    return false;
                }
            },
            carIndex(): number {
                try {
                    return configFromQueryParameter().carIndex;
                } catch (error) {
                    return 0;
                }
            },
            matchMakerUrl(state): string {
                if (state.stage === Stage.PROD) {
                    return `https://matchmaker.${state.region}-web-streaming.avp.tech`;
                } else {
                    return `https://matchmaker.${state.stage}-euw1.${state.region}-web-streaming.avp.tech`;
                }
            },
            relevantStageRegionCombinations(state): StageRegionCombination[] {
                const filteredStages = mapPortalStageToDisplayedStages(state.portalStage);
                const nonProdCombinations = filteredStages
                    .filter((stage) => stage !== Stage.PROD)
                    .map((stage) => ({
                        text: stage,
                        stage,
                        region: Region.EU_WEST_1,
                    }));

                if (!filteredStages.includes(Stage.PROD)) {
                    return nonProdCombinations;
                }

                const prodCombinations = allRegions.map((region: Region) => ({
                    stage: Stage.PROD,
                    region,
                    text: `${Stage.PROD}-${region}`,
                }));
                return prodCombinations.concat(nonProdCombinations);
            },
        },
        mutations: {
            [MUTATIONS.SET_ERROR_MESSAGE](state, errorMessage: string) {
                state.infoMessage = errorMessage;
                state.infoMessageIsError = true;
            },
            [MUTATIONS.SET_INFO_MESSAGE](state, infoMessage: string) {
                state.infoMessage = infoMessage;
                state.infoMessageIsError = false;
            },
            [MUTATIONS.CLEAR_INFO_MESSAGE](state) {
                state.infoMessage = "";
                state.infoMessageIsError = false;
            },
        },
        actions: {
            async [ACTIONS.UPDATE_MATCHMAKER_DATA]({state, getters}) {
                state.isUpdatingMatchMakerData = true;
                const start = Date.now();
                const response = await fetch(getters.matchMakerUrl + "/sb/igs", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        auth: "this1ssp@rta",
                    },
                });
                if (response.ok) {
                    state.matchMakerData = await response.json();
                } else {
                    console.error(`error while fetching data: ${response.status}: ${response.statusText}`);
                }
                const end = Date.now();
                const timeTakenInMs = end - start;
                if (timeTakenInMs < 1000) {
                    // sleep the remaining time to display the loading circle for at least 1 sec
                    await sleep(1000 - timeTakenInMs);
                }
                state.isUpdatingMatchMakerData = false;
            },
            async [ACTIONS.START_POLLING_MATCHMAKER]({state, dispatch}, payload: {stage: Stage; region: Region}) {
                state.stage = payload.stage;
                state.region = payload.region;
                state.matchMakerData = [];
                dispatch(ACTIONS.UPDATE_MATCHMAKER_DATA);
                state.pollingInterval = setInterval(() => dispatch(ACTIONS.UPDATE_MATCHMAKER_DATA), 10000);
            },
            async [ACTIONS.STOP_POLLING_MATCHMAKER]({state}) {
                clearInterval(state.pollingInterval as NodeJS.Timeout);
                state.pollingInterval = 0;
            },
            async [ACTIONS.UPDATE_INSTANCE_STATUS_MESSAGE]({state, commit}, payload: ClientConfig) {
                try {
                    const relevantMatchMakerDataEntry: MatchMakerDataEntry = state.matchMakerData
                        .filter((entry: MatchMakerDataEntry) => entry.brand === payload.brand)
                        .filter((entry: MatchMakerDataEntry) =>
                            entry.users.find(
                                (user: ServiceMarketsCombination) =>
                                    user.service === payload.service &&
                                    (user.markets.includes("ALL") || user.markets.includes(payload.market)),
                            ),
                        )[0];
                    const freeAves = relevantMatchMakerDataEntry.aves.freeAves.length;
                    commit(MUTATIONS.SET_INFO_MESSAGE, `Rendering units available: ${freeAves}`);
                } catch (error) {
                    commit(
                        MUTATIONS.SET_INFO_MESSAGE,
                        `An error occurred. Please verify the provided config is correct.`,
                    );
                }
            },
        },
    });
}

export default createStore();
