
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {IController} from "@ave2cloud/jetstream/dist/src/interfaces/controller";
import StreamApi from "@/components/StreamApi.vue";
import {Brand, CameraMode, ClientConfig, Market, Service} from "@ave2cloud/jetstream/dist/src/types";
import jetStreamApplication from "@ave2cloud/jetstream";
import Button from "@/components/Button.vue";
import IconToggle from "@/components/IconToggle.vue";
import {IApi} from "@ave2cloud/jetstream/dist/src/interfaces/api";
import store, {ACTIONS, MUTATIONS} from "@/store/store";
import {Getter, State} from "@/store/decorators";
import MessageBox from "@/components/MessageBox.vue";
import {Region, Stage} from "@/types";

@Component({
    components: {
        MessageBox,
        IconToggle,
        Button,
        StreamApi,
    },
})
export default class Stream extends Vue {
    @Prop() market!: Market;
    @Prop() service!: Service;
    @Prop() brand!: Brand;
    @Prop() stage!: Stage;
    @Prop() region!: Region;
    @State("isUpdatingMatchMakerData") isUpdatingMatchMakerData!: boolean;
    @State("isPublicDemo") isPublicDemo!: boolean;
    @Getter("matchMakerUrl") matchMakerUrl!: string;
    @Getter("clientConfigFromQueryParameter") clientConfigFromQueryParameter!: ClientConfig;
    @Getter("startVideoAutomatically") startVideoAutomatically!: boolean;
    private jetStreamContainerId = "jet-stream-container";
    private jetStreamController?: IController;
    private jetStreamApi?: IApi;
    private isStreamPlaying = false;
    private isFullScreen = false;
    private isAnimationSpotsVisible = false;
    private showStream = false;
    private showStreamApi = false;
    private showStreamIcons = true;
    private showSteeringAngleSlider = false;
    private cameraMode = CameraMode.Standard;
    private steeringAngle = 0;

    get clientConfig(): ClientConfig {
        if (this.isPublicDemo) {
            return this.clientConfigFromQueryParameter;
        }
        return {service: this.service, market: this.market, brand: this.brand};
    }

    private async disconnect() {
        await this.jetStreamController?.close();
        this.isStreamPlaying = false;
        this.showStreamApi = false;
        this.showStream = false;
        store.dispatch(ACTIONS.UPDATE_MATCHMAKER_DATA, this.clientConfig);
    }

    @Watch("brand")
    @Watch("stage")
    @Watch("region")
    @Watch("service")
    @Watch("market")
    private async refreshStream() {
        await this.disconnect();
        await store.dispatch(ACTIONS.UPDATE_MATCHMAKER_DATA, this.clientConfig);
    }

    @Watch("isUpdatingMatchMakerData")
    private updateInstanceMessage() {
        if (!this.isUpdatingMatchMakerData) {
            store.dispatch(ACTIONS.UPDATE_INSTANCE_STATUS_MESSAGE, this.clientConfig);
        }
    }

    private async connect() {
        try {
            store.commit(MUTATIONS.SET_INFO_MESSAGE, "Connecting to a 3D stream.");
            this.jetStreamController = await jetStreamApplication.init({
                instanceMatchingUrl: this.matchMakerUrl,
            });
            await this.jetStreamController.connectWithClientConfig(this.jetStreamContainerId, this.clientConfig);
            this.jetStreamApi = this.jetStreamController.api;
            this.isStreamPlaying = true;
            this.showStreamApi = true;
            store.commit(MUTATIONS.CLEAR_INFO_MESSAGE);
        } catch (error: any) {
            store.commit(MUTATIONS.SET_ERROR_MESSAGE, `Error: ${error.message}`);
        }
    }

    private toggleFullScreen(): void {
        this.isFullScreen = !this.isFullScreen;
        this.showStreamApi = !this.showStreamApi;
        this.$emit("isFullScreen", this.isFullScreen);
    }

    private toggleCameraMode(): void {
        this.cameraMode = this.cameraMode === CameraMode.Standard ? CameraMode.FreeOrbit : CameraMode.Standard;
        this.jetStreamApi?.setCameraMode(this.cameraMode);
    }

    private toggleAnimationSpots(): void {
        this.isAnimationSpotsVisible = !this.isAnimationSpotsVisible;
        this.jetStreamApi?.setAnimationSpotsVisibility(this.isAnimationSpotsVisible);
    }

    private toggleSteeringAngleSlider(): void {
        this.showSteeringAngleSlider = !this.showSteeringAngleSlider;
        if (!this.showSteeringAngleSlider) {
            this.steeringAngle = 0;
        }
    }

    @Watch("steeringAngle")
    private updateSteeringAngle(newAngle: number): void {
        this.jetStreamApi?.setSteeringAngle(-newAngle);
    }

    beforeMount() {
        if (this.isPublicDemo) {
            this.isFullScreen = true;
            this.showStreamIcons = false;
        }
        store.dispatch(ACTIONS.UPDATE_MATCHMAKER_DATA, this.clientConfig);
        if (this.startVideoAutomatically) {
            this.connect();
        }
    }
}
