import {createDecorator, VueDecorator} from "vue-class-component";
import store from "@/store/store";

export function Getter(getterType: string): VueDecorator {
    return createDecorator((options, key) => {
        if (!options.computed) options.computed = {};
        options.computed[key] = function () {
            // @ts-ignore
            return store.getters[getterType];
        };
    });
}

export function State(stateType: string): VueDecorator {
    return createDecorator((options, key) => {
        if (!options.computed) options.computed = {};
        options.computed[key] = function () {
            // @ts-ignore
            return store.state[stateType];
        };
    });
}
