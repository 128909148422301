export type ServiceMarketsCombination = {
    service: string;
    markets: string[];
};

export type MatchMakerDataEntry = {
    aves: {
        freeAves: string[];
        inUseAves: string[];
    };
    brand: string;
    id: string;
    users: ServiceMarketsCombination[];
    versions: string[];
};

export enum Region {
    EU_WEST_1 = "eu-west-1",
    US_EAST_1 = "us-east-1",
    AP_SOUTHEAST_1 = "ap-southeast-1",
}

export const allRegions: Array<Region> = Object.values(Region);

export enum Stage {
    PROD = "PROD",
    QA = "QA",
    PRELIVE = "PRELIVE",
    DEV = "DEV",
    BETA = "BETA",
}

export enum PortalStage {
    local = "local",
    development = "development",
    preprod = "preprod",
    production = "production",
}

export type StageRegionCombination = {stage: Stage; region: Region; text: string};
