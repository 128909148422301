
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class Input extends Vue {
    @Prop({type: String, default: ""}) private value!: string;
    @Prop({type: String, default: ""}) private label!: string;
    @Prop({type: String, default: ""}) private hint!: string;
    @Prop({type: String, default: ""}) private errorMessage!: string;
}
