
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {
    AnimationDirection,
    AnimationId,
    CameraId,
    EnvironmentId,
    InteractionSpot,
} from "@ave2cloud/jetstream/dist/src/types";
import {IApi} from "@ave2cloud/jetstream/dist/src/interfaces/api";
import Dropdown, {DropdownItems} from "@/components/Dropdown.vue";
import Input from "@/components/Input.vue";
import {BrandExample, kvpsToBrandConfigMap} from "@/brand-config";
import {Getter, State} from "@/store/decorators";

@Component({
    components: {Input, Dropdown},
})
export default class StreamApi extends Vue {
    @Prop() api!: IApi;
    @Prop() brand!: string;
    @State("isPublicDemo") isPublicDemo!: boolean;
    @Getter("startVideoAutomatically") startVideoAutomatically!: boolean;
    @Getter("carIndex") carIndex!: number;
    private availableAnimations: Array<AnimationId> = [];
    private animationsStates: Map<AnimationId, AnimationDirection> = new Map();
    private availableEnvironments: DropdownItems = [];
    private availableCameras: Array<CameraId> = [];
    private oldHotSpots: Array<InteractionSpot> = [];
    private animationRunning = false;
    private prCodesSelected = "";

    get availablePrCodesDropdownItems(): DropdownItems {
        return kvpsToBrandConfigMap[this.brand].examples.map((example: BrandExample) => ({
            text: example.model,
            value: example.prCode,
        }));
    }

    @Watch("prCodesSelected")
    private async selectCar(prCodesSelectedNewValue: string): Promise<void> {
        if (prCodesSelectedNewValue) {
            await this.api.selectCar(prCodesSelectedNewValue.split(","));
            this.availableEnvironments = (await this.api.loadEnvironments()).map((environment) => ({
                value: environment.environmentId,
                text: environment.label,
            }));
            this.availableAnimations = (await this.api.loadAnimations()).map((animation) => animation.animationId);
            this.availableCameras = (await this.api.loadCameras()).map((camera) => camera.cameraId);
            this.api.enableInteractionSpots(this.handleHotspots);
        }
    }

    private async initializeCar(): Promise<void> {
        try {
            this.prCodesSelected = this.availablePrCodesDropdownItems[this.carIndex].value;
        } catch (error) {
            console.warn(`Did not find car with carIndex=${this.carIndex}. Falling back to index 0.`);
            this.prCodesSelected = this.availablePrCodesDropdownItems[0].value;
        }
    }

    private async triggerAnimation(animation: AnimationId) {
        const newAnimationDirection =
            this.animationsStates.get(animation) === AnimationDirection.forward
                ? AnimationDirection.reverse
                : AnimationDirection.forward;
        const response = await this.api.triggerAnimation(animation, newAnimationDirection);
        this.animationRunning = true;
        setTimeout(() => {
            this.animationRunning = false;
        }, response.animationDuration * 1000);
        this.animationsStates.set(animation, newAnimationDirection);
    }

    private async changeCamera(camera: CameraId) {
        await this.api.changeCamera(camera);
    }

    private async changeEnvironment(environment: EnvironmentId) {
        await this.api.changeEnvironment(environment);
    }

    private async beforeMount() {
        if (this.startVideoAutomatically) {
            await this.api.changeEnvironment("VW_Neutral");
            await Promise.all([
                this.api.resetAllAnimations(),
                this.api.startOrResumeCameraOrbit("dynamic"),
                this.initializeCar(),
            ]);
        } else {
            this.initializeCar();
        }
        this.$emit("streamSetupFinished");
    }

    private beforeDestroy() {
        this.api.disableInteractionSpots();
        // disabling the interaction spots takes a short amount, so we wait a few ms fore removing the html elements
        setTimeout(() => this.removeOldHotspots([]), 200);
    }

    private handleHotspots(interactionSpots: InteractionSpot[]): void {
        const streamContainer = document.getElementById("jet-stream-container");
        if (!streamContainer) {
            return;
        }

        const newHotSpots = interactionSpots.filter((hotspot) => hotspot.type === "Hotspot");

        newHotSpots.forEach((interactionSpot: InteractionSpot) =>
            this.createOrUpdateHotspotElement(interactionSpot, streamContainer),
        );

        this.removeOldHotspots(newHotSpots);
    }

    private createOrUpdateHotspotElement(interactionSpot: InteractionSpot, streamContainer: HTMLElement): void {
        let existingHotspotElement = document.getElementById(interactionSpot.id);

        if (!existingHotspotElement) {
            const newHotSpotElement = document.createElement("div");
            newHotSpotElement.style.border = "1px solid black";
            newHotSpotElement.style.textShadow = "white 1px 1px";
            newHotSpotElement.style.boxShadow = "white 1px 1px";
            newHotSpotElement.style.inlineSize = "fit-content";
            newHotSpotElement.style.position = "absolute";
            newHotSpotElement.style.zIndex = "1000";
            newHotSpotElement.textContent = interactionSpot.id;
            newHotSpotElement.id = interactionSpot.id;
            streamContainer.appendChild(newHotSpotElement);

            existingHotspotElement = newHotSpotElement;
        }

        existingHotspotElement.style.top = `${interactionSpot.location.y * 100}%`;
        existingHotspotElement.style.left = `${interactionSpot.location.x * 100}%`;
    }

    private removeOldHotspots(newHotSpots: InteractionSpot[]) {
        this.oldHotSpots
            .filter((hotspot: InteractionSpot) => !newHotSpots.find((element) => element.id === hotspot.id))
            .forEach((hotspot: InteractionSpot) => {
                // eslint-disable-next-line no-unused-expressions
                document.getElementById(hotspot.id)?.remove();
            });

        this.oldHotSpots = newHotSpots;
    }
}
