
import {Component, Vue} from "vue-property-decorator";
import SelfService from "./views/SelfService.vue";

@Component({
    components: {
        SelfService,
    },
})
export default class extends Vue {}
