
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class IconToggle extends Vue {
    @Prop({type: String, default: ""}) private firstIcon!: string;
    @Prop({type: String, default: ""}) private secondIcon!: string;
    @Prop({type: Boolean, default: false}) private disabled!: boolean;
    private currentIconHelper = "";

    get currentIcon(): string {
        return this.currentIconHelper || this.firstIcon;
    }

    set currentIcon(newValue: string) {
        this.currentIconHelper = newValue;
    }

    private toggle(): void {
        this.currentIcon = this.currentIcon === this.firstIcon ? this.secondIcon : this.firstIcon;
        this.$emit("toggle");
    }
}
