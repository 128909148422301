
import {Component, Vue, Watch} from "vue-property-decorator";
import Dropdown, {DropdownItems} from "@/components/Dropdown.vue";
import Input from "@/components/Input.vue";
import Stream from "@/components/Stream.vue";
import {kvps, kvpsToBrandConfigMap} from "@/brand-config";
import {Getter, State} from "@/store/decorators";
import store from "@/store/store";
import {MatchMakerDataEntry, Region, ServiceMarketsCombination, Stage, StageRegionCombination} from "@/types";
import MessageBox from "@/components/MessageBox.vue";

type StageDropdownItem = {
    stage: Stage;
    region: Region;
};

@Component({
    components: {
        MessageBox,
        Stream,
        Input,
        Dropdown,
    },
})
export default class StreamingDemo extends Vue {
    @State("stage") stageSelected!: Stage;
    @State("region") regionSelected!: Region;
    @State("isUpdatingMatchMakerData") isUpdatingMatchMakerData!: boolean;
    @Getter("relevantStageRegionCombinations") relevantStageRegionCombinations!: StageRegionCombination[];
    private isFullScreen = false;
    private brandSelected: string = kvps[0];
    private serviceSelected: string = this.serviceDropDownItems.length > 0 ? this.serviceDropDownItems[0] : "";
    private marketSelected: string =
        this.relevantMarketsDropDownItems.length > 0 ? this.relevantMarketsDropDownItems[0].value : "";

    private readonly PROD_TEST_MARKET = "CCK";

    get marketForStream(): string {
        if (this.marketSelected) {
            return this.marketSelected === "ALL" ? "DEU" : this.marketSelected;
        }
        return "";
    }

    get brandDropdownItems(): DropdownItems {
        return kvps.map((kvps: string) => ({
            text: kvpsToBrandConfigMap[kvps].name,
            value: kvps,
        }));
    }

    get stageDropdownItems(): DropdownItems {
        return this.relevantStageRegionCombinations.map((entry: StageRegionCombination) => ({
            text: entry.text,
            value: {
                stage: entry.stage,
                region: entry.region,
            },
        }));
    }

    get stageDropdownItemSelected(): StageDropdownItem {
        return {
            stage: this.stageSelected,
            region: this.regionSelected,
        };
    }

    set stageDropdownItemSelected(newValue: StageDropdownItem) {
        store.state.stage = newValue.stage;
        store.state.region = newValue.region;
    }

    get relevantServiceMarketCombinations(): ServiceMarketsCombination[] {
        return store.state.matchMakerData
            .filter((entry: MatchMakerDataEntry) => entry.brand === this.brandSelected)
            .map((entry: MatchMakerDataEntry) => entry.users)
            .flat();
    }

    get serviceDropDownItems(): string[] {
        return this.relevantServiceMarketCombinations.map((entry) => entry.service);
    }

    @Watch("serviceDropDownItems")
    private updateSelectedService(newServices: string[]): void {
        if (!newServices.includes(this.serviceSelected)) {
            this.serviceSelected = newServices[0];
        }
    }

    get relevantMarketsDropDownItems(): DropdownItems {
        const markets = this.relevantServiceMarketCombinations
            .filter((entry) => entry.service === this.serviceSelected)
            .map((entry) => entry.markets)
            .flat();
        return this.convertToDropDownItems(markets);
    }

    /**
     * Converts array of markets (coming from the matchmaker)
     * to array of DropdownItems.
     * Adds hint about a test market available on production stages.
     */
    private convertToDropDownItems(markets: string[]): DropdownItems {
        const items: DropdownItems = [];
        for (const market of markets) {
            if (market === this.PROD_TEST_MARKET) {
                items.push({
                    text: this.PROD_TEST_MARKET + " (test market)",
                    value: market,
                });
            } else {
                items.push({
                    text: market,
                    value: market,
                });
            }
        }
        return items;
    }

    @Watch("relevantMarketsDropDownItems")
    private updateSelectedMarket(newMarkets: DropdownItems) {
        this.marketSelected = newMarkets.length > 0 ? newMarkets[0].value : "";
    }

    private disconnectStream(): void {
        // @ts-ignore
        this.$refs.streamRef.disconnect();
    }
}
